import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import queryString from 'query-string'

import { PageLayout, ProductList, PageBreadcrumb } from '../../components'
import { l } from '../../lib/locale'
import { context } from '../../store'

const ElevateActionTemplate = ({ data, pageContext }) => {
  const { store } = useContext(context)
  const { lang, page } = pageContext

  let rule = null
  let items = null
  if (store && store.app && store.app.cartrules) {
    rule = store.app.cartrules.find(i => i.display_code && i.display_code.toUpperCase() === "ELEVATE2023")
    items = data.allShopProduct.edges.filter(({ node }) => {
      return (rule && rule.items && rule.items.includes(+node.id_product))
    }).map(i => i.node)
  }

  const navigation = {
    page: 0
  }
  if (typeof window !== 'undefined') {
    const qs = queryString.parse(window.location.search)
    navigation.page = qs.page ? +qs.page : 0
  }

  const header = (
    <>
      <p dangerouslySetInnerHTML={{ __html: l('action-elevate2023-intro1', lang) }} />
      <p dangerouslySetInnerHTML={{ __html: l('action-elevate2023-intro2', lang) }} />
      <p dangerouslySetInnerHTML={{ __html: l('action-elevate2023-intro3', lang) }} />
      <p dangerouslySetInnerHTML={{ __html: l('action-elevate2023-intro4', lang) }} />
    </>
  )

  return (
    <PageLayout lang={lang} switcher={page}>
      <div className="product-list-4 product-list-breadcrumbs">
        <PageBreadcrumb
          lang={lang}
          current={page}
          items={[ 'homepage' ]}
          />
        {rule ? (
          <ProductList
            title={page[`title_${lang.suffix}`]}
            header={header}
            products={items}
            attributes={data.allShopAttribute.edges.map(i => i.node)}
            attribute_groups={data.allShopAttributeGroup.edges.map(i => i.node)}
            lang={lang}
            navigation={navigation}
            keyPrefix="elevate2023" />
        ) : (
          <div className="text-center w-100 my-5">
            <h1>Coming soon!</h1>
            <h2>Stay tuned for more...</h2>
          </div>
        )}
      </div>
    </PageLayout>
  )
}

export default ElevateActionTemplate

export const query = graphql`
  query ElevateActionTemplateQuery {
    allShopProduct {
      edges {
        node {
          ...ProductSearchItem
        }
      }
    }
    allShopAttributeGroup {
      edges {
        node {
          group_type
          id_attribute_group
          is_color_group
          name_en
          name_nl
          position
          public_name_en
          public_name_nl
        }
      }
    }
    allShopAttribute {
      edges {
        node {
          id_attribute
          id_attribute_group
          color
          name_en
          name_nl
          position
        }
      }
    }
  }
`
